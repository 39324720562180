<template>
    <div 
        class="last-place" 
        v-bind:style="{
            'top': position + 'px',
            'display': isVisible? 'block':'none', 
        }">
        
    </div>
</template>

<script>

export default {
    name: "LastPlace",
    props: {
        isVisible: Boolean,
        position: Number,
    },
}
</script>

<style scoped>
.last-place {
    position: absolute;
    width: calc(100% - 5px);
    height: calc(6vh - 6px);
    border: 3px dashed rgb(139, 139, 219);
    z-index: 2;
}
</style>